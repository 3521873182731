import { KeyMethods } from 'src/modules/payment/paymentModule.types'

type DefinePaymentMethodDefaultProps = {
  visibleMethodsPayment: KeyMethods[]
  nupayDefault: boolean
  methodEmptyDefault: boolean
}

export function definePaymentMethodDefault({
  nupayDefault,
  visibleMethodsPayment,
  methodEmptyDefault,
}: DefinePaymentMethodDefaultProps): KeyMethods | null {
  const firstOption = visibleMethodsPayment[0] || null
  if (visibleMethodsPayment.length === 1) {
    return firstOption
  }

  if (methodEmptyDefault) return null

  if (nupayDefault && visibleMethodsPayment.includes('nupay')) return 'nupay'

  return firstOption
}
