import { Box, Button, Text } from '@queimadiaria/ui'
import { FC } from 'react'

import { useCheckoutParamsFromUrl } from 'src/features/layout/hooks/useCheckoutParamsFromUrl'

import { ErrorMessage } from '../../layout/components/ErrorMessage'
import { backPage } from '../utils/backPage'

export const CrossSellTokenErrorMessage: FC = () => {
  const { qp_origin } = useCheckoutParamsFromUrl()

  const redirect = () => {
    backPage(qp_origin)
  }

  return (
    <ErrorMessage title=' Ops! Tempo de compra expirado.'>
      <Text variant='body-1' color='#595769'>
        <ul>
          Por favor, clique no botão abaixo e tente realizar sua compra
          novamente.
        </ul>
      </Text>
      <Box
        sx={{
          display: 'flex',
          justifyContent: ['center', 'center', 'flex-end'],
        }}
      >
        <Button children='OK' onClick={redirect} type='button' size='large' />
      </Box>
    </ErrorMessage>
  )
}
