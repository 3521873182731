import { env } from 'src/configs/env'
import { apiPayments, authenticatedApi } from 'src/infra/api'
import { createUnknownSerializedApiError } from 'src/utils/createUnknownSerializedApiError'
import { fromPaymentErrorToSerializedApiError } from 'src/utils/fromPaymentErrorToSerializedApiError'

import {
  RequestNupayPaymentResponse,
  RequestPaymentCrossSellResponse,
  RequestPaymentResponse,
  RequestPaymnentPixResponse,
  RequestVerificationPixPaymentResponse,
  TryRequestNupayPaymentPayload,
  TryRequestPaymentPayload,
  TryRequestPaymentPayloadCrossSell,
  TryRequestPaymentPixPayload,
  TryRequestPixPaymentVerification,
} from '../typings/paymentTypings'

async function tryToCreatePayment(payload: TryRequestPaymentPayload) {
  const data = await apiPayments
    .url(`${env.PATH_PAYMENTS_CRED_CARD}`)
    .post(payload)
    .internalError(() => {
      throw createUnknownSerializedApiError()
    })
    .badRequest(error => {
      throw fromPaymentErrorToSerializedApiError(error.json)
    })
    .fetchError(() => {
      throw createUnknownSerializedApiError()
    })

    .json()

  return {
    ...data,
    creditCardRedirectUrl: data.checkout.successCardRoute,
  } as RequestPaymentResponse
}

async function tryToCreateNupayPayment(payload: TryRequestNupayPaymentPayload) {
  const data = await apiPayments
    .url(`${env.PATH_PAYMENTS_NU_PAY}/payment`)
    .post(payload)
    .internalError(() => {
      throw createUnknownSerializedApiError()
    })
    .badRequest(error => {
      throw fromPaymentErrorToSerializedApiError(error.json)
    })
    .fetchError(() => {
      throw createUnknownSerializedApiError()
    })

    .json()

  return {
    ...data,
  } as RequestNupayPaymentResponse
}

async function tryToCreatePaymentFromPix(payload: TryRequestPaymentPixPayload) {
  const data = await apiPayments
    .url(`${env.PATH_PAYMENTS_PIX}`)
    .post(payload)
    .internalError(() => {
      throw createUnknownSerializedApiError()
    })
    .badRequest(error => {
      throw fromPaymentErrorToSerializedApiError(error.json)
    })
    .fetchError(() => {
      throw createUnknownSerializedApiError()
    })
    .json()

  return {
    status: data.status,
    expiresAt: data.pixData.pixExpirationDate,
    qrCode: data.pixData.qrCodeData,
  } as RequestPaymnentPixResponse
}

async function tryToVerificationPixPayment(
  pixDataAndUserDocument: TryRequestPixPaymentVerification
) {
  const data = await apiPayments
    .url(`${env.PATH_PAUMENTS_PIX_STATUS}`)
    .post(pixDataAndUserDocument)
    .internalError(() => {
      throw createUnknownSerializedApiError()
    })
    .badRequest(error => {
      throw fromPaymentErrorToSerializedApiError(error.json)
    })
    .fetchError(() => {
      throw createUnknownSerializedApiError()
    })
    .json()

  return data as RequestVerificationPixPaymentResponse
}

async function tryToCreatePaymentCrossSell(
  card: TryRequestPaymentPayloadCrossSell
) {
  const data = await authenticatedApi
    .url(`/payments/payment/cross-sell`)
    .post(card)
    .internalError(() => {
      throw createUnknownSerializedApiError()
    })
    .unauthorized(error => {
      throw fromPaymentErrorToSerializedApiError(error.json)
    })
    .badRequest(error => {
      throw fromPaymentErrorToSerializedApiError(error.json)
    })
    .fetchError(() => {
      throw createUnknownSerializedApiError()
    })
    .json<RequestPaymentCrossSellResponse>()

  return data as RequestPaymentCrossSellResponse
}

export const paymentsApi = {
  tryToCreatePayment,
  tryToCreateNupayPayment,
  tryToCreatePaymentFromPix,
  tryToVerificationPixPayment,
  tryToCreatePaymentCrossSell,
}
