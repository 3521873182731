import { useCheckoutDetails } from 'src/features/plan/checkout/hooks/useCheckoutDetails'
import { CheckoutDetails } from 'src/features/plan/checkout/typings/checkoutDetailsTypings'
import { getUrlForAsset } from 'src/utils/assetsUtils'

export function useImages(defaultData?: CheckoutDetails) {
  const { checkoutDetails } = useCheckoutDetails()

  const checkoutData = checkoutDetails || defaultData

  return {
    banner: getUrlForAsset(checkoutData?.bannerWeb),
    bannerMobile: getUrlForAsset(checkoutData?.bannerMobile),
    footerBadges: getUrlForAsset(checkoutData?.footerBadges),
    logo: getUrlForAsset(checkoutData?.logo),
    sideBanner: getUrlForAsset(checkoutData?.bannerSideWeb),
  }
}
