import { Box, Button, Text } from '@queimadiaria/ui'
import { useAtom } from 'jotai'
import { FC } from 'react'
import { useFormContext } from 'react-hook-form'

import { PaymentErrorMessageProps } from 'src/features/checkout/typings/PaymentErrorMessageTypings'
import { ErrorMessage } from 'src/features/layout/components/ErrorMessage'
import { paymentAtoms } from 'src/features/payment/atoms/paymentAtoms'

import { useCustomerCards } from '../hooks/useCustomerCards'
import {
  PaymentType,
  PaymentTypeEnum,
} from '../types/crossSellCheckoutFormTypings'

export const CrossSellPaymentErrorMessage: FC<PaymentErrorMessageProps> = ({
  isAnRefused,
}) => {
  const { getValues } = useFormContext<PaymentType>()

  const paymentType = getValues('paymentType')

  const isPaymentByCardId = paymentType === PaymentTypeEnum.CardId

  return (
    <>
      {isPaymentByCardId ? (
        <PaymentErrorByCardId isAnRefused={isAnRefused} />
      ) : (
        <PaymentErrorByCreditCardData isAnRefused={isAnRefused} />
      )}
    </>
  )
}

const PaymentErrorByCardId: FC<PaymentErrorMessageProps> = ({
  isAnRefused,
}) => {
  const { setValue } = useFormContext<PaymentType>()
  const { customerCards } = useCustomerCards()

  const [, clearErrors] = useAtom(paymentAtoms.clearErrors)

  const hasOnlyOneCard = customerCards.length === 1

  const onPressPayWithOtherCard = () => {
    if (hasOnlyOneCard) setValue('paymentType', PaymentTypeEnum.CreditCard)
    clearErrors()
  }
  return (
    <ErrorMessage
      title={
        isAnRefused ? 'Ops! Erro no pagamento.' : 'Ops! Falha no pagamento.'
      }
    >
      <Text variant='body-1' color='#595769'>
        <ol>
          <li>
            Verifique se o cartão está com limite liberado e com saldo
            suficiente.
          </li>
          <li>
            Caso o erro continue, entre em contato com a administradora do seu
            cartão.
          </li>
          <li>Ou tente novamente usando outro cartão.</li>
        </ol>
      </Text>
      <Box
        sx={{
          display: 'flex',
          justifyContent: ['center', 'center', 'flex-end'],
        }}
      >
        <Button
          children={
            <Text
              variant='subheadline-1'
              css={{
                fontSize: '14px',
                lineHeight: '0px',
              }}
            >
              Usar Outro Cartão
            </Text>
          }
          onClick={onPressPayWithOtherCard}
          type='button'
          size='large'
        />
      </Box>
    </ErrorMessage>
  )
}

const PaymentErrorByCreditCardData: FC<PaymentErrorMessageProps> = ({
  isAnRefused,
}) => {
  const [, clearErrors] = useAtom(paymentAtoms.clearErrors)

  return (
    <ErrorMessage
      title={
        isAnRefused ? 'Ops! Erro no pagamento.' : 'Ops! Falha no pagamento.'
      }
    >
      <Text variant='body-1' color='#595769'>
        <ol>
          <li>Verifique se as informações do seu cartão estão corretas.</li>
          <li>
            Se as informações estiverem corretas, verifique se o cartão está com
            limite liberado e com saldo suficiente.
          </li>
          <li>
            Caso o erro continue, entre em contato com a administradora do seu
            cartão.
          </li>
          <li>
            Ou se tiver outro cartão de crédito, clique no botão abaixo e faça
            sua inscrição com ele.
          </li>
        </ol>
      </Text>
      <Box
        sx={{
          display: 'flex',
          justifyContent: ['center', 'center', 'flex-end'],
        }}
      >
        <Button
          children={
            <Text
              variant='subheadline-1'
              css={{
                fontSize: '14px',
                lineHeight: '0px',
              }}
            >
              Editar Cartão
            </Text>
          }
          onClick={clearErrors}
          type='button'
          size='large'
        />
      </Box>
    </ErrorMessage>
  )
}
