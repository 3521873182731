import { env } from 'src/configs/env'

export const backPage = (origin?: string) => {
  const isFromAndroidApp = origin?.includes('android') ?? false

  if (isFromAndroidApp) {
    setTimeout(() => {
      window.location.href = `${env.MOBILE_BASE_URL}`
    }, 300)
  } else {
    setTimeout(() => {
      window.history.back()
    }, 300)
  }
}
