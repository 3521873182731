import { Product } from 'src/features/plan/product/typings/productTypings'

/**
 * Checks if a product is either a monthly subscription or a direct fallback product.
 *
 * @param product - The product to check.
 * @returns A boolean indicating whether the product is a monthly subscription or a direct fallback.
 */
export const isMonthlySubscriptionOrDirectFallback = (
  product: Product | undefined
) => {
  return product?.subscriptionType === 'monthly' || product?.isDirectFallback
}
