import {
  CheckoutFormPayload,
  CheckoutFormPayloadWithBillet,
  CheckoutFormPayloadWithCreditCard,
  CheckoutFormPayloadWithLegacyCreditCard,
  CheckoutFormPayloadWithNupay,
  CheckoutFormPayloadWithPix,
} from '../typings/checkoutFormTypings'
import { PaymentMethods } from '../typings/legacyCheckoutTypings'

export function isCheckoutFormPayloadWithLegacyCreditCard(
  payload: CheckoutFormPayload
): payload is CheckoutFormPayloadWithLegacyCreditCard {
  return payload.paymentMethod === PaymentMethods.LegacyCreditCard
}

export function isCheckoutFormPayloadWithCreditCard(
  payload: CheckoutFormPayload
): payload is CheckoutFormPayloadWithCreditCard {
  return payload.paymentMethod === PaymentMethods.CreditCard
}

export function isCheckoutFormPayloadWithBillet(
  payload: CheckoutFormPayload
): payload is CheckoutFormPayloadWithBillet {
  return payload.paymentMethod === PaymentMethods.Billet
}

export function isCheckoutFormPayloadWithNupay(
  payload: CheckoutFormPayload
): payload is CheckoutFormPayloadWithNupay {
  return payload.paymentMethod === PaymentMethods.Nupay
}

export function isCheckoutFormPayloadWithPix(
  payload: CheckoutFormPayload
): payload is CheckoutFormPayloadWithPix {
  return payload.paymentMethod === PaymentMethods.Pix
}
