import { SerializedApiError } from 'src/infra/typings/globalTypings'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fromPaymentErrorToSerializedApiError = (error: any) => {
  const serializedError: SerializedApiError = {
    isSerializedApiError: true,
    statusCode: error.statusCode,
    code: error?.data?.operationStatus || error?.data?.status,
    message: error.response,
    data: {
      src: error?.data?.src,
      email: error?.data?.email,
      type: error?.data?.type,
    },
  }

  return serializedError
}
