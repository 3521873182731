import { differenceInMilliseconds, parseISO } from 'date-fns'
import { useAtom } from 'jotai'
import { useCallback } from 'react'

import { pageInfo } from '../atoms/pageInfoAtoms'
import { SpecialOfferCheckout } from '../typings/specialOfferCheckout'

type InitInfoUpdateProps = {
  specialOfferCheckout: SpecialOfferCheckout | null
}

export function usePageInfoStore() {
  const { specialOffer: special, referenceTimeSpecialOffer: referenceTime } =
    pageInfo

  const [specialOffer, setSpecialOffer] = useAtom(special)
  const [referenceTimeSpecialOffer, setReferenceTimeSpecialOffer] =
    useAtom(referenceTime)

  const handleRedirectDefaultOffer = useCallback(() => {
    if (!specialOffer) return
    window.location.href = specialOffer.redirectUrl
  }, [specialOffer])

  const init = useCallback(
    ({ specialOfferCheckout }: InitInfoUpdateProps) => {
      if (specialOffer) return

      if (!!specialOfferCheckout) {
        setSpecialOffer(specialOfferCheckout)
        setReferenceTimeSpecialOffer(
          differenceInMilliseconds(
            parseISO(specialOfferCheckout.currentTime),
            new Date()
          )
        )
      }
    },
    [specialOffer]
  )

  return {
    specialOffer,
    init,
    handleRedirectDefaultOffer,
    referenceTimeSpecialOffer,
  }
}
