import { useQuery } from '@tanstack/react-query'
import { useAtom } from 'jotai'
import { useEffect } from 'react'

import { isSerializedApiError } from 'src/utils/isSerializedApiError'

import { customerApi } from '../api/customerApi'
import { customerAtoms } from '../atoms/customerAtoms'
import { CustomerCreditCard } from '../types/customerCardsTyping'

interface WretcherError extends Error {
  response: Response
}

export function useCustomerCards() {
  const [, setHasErrorWhenGetCustomerCards] = useAtom(
    customerAtoms.hasErrorWhenGetCustomerCards
  )
  const [, setCreatingErrorDetails] = useAtom(
    customerAtoms.creatingErrorDetails
  )
  const { data, isFetching, error } = useQuery<
    CustomerCreditCard[],
    WretcherError
  >({ queryKey: ['customer-cards'], queryFn: customerApi.fetchCustomerCards })

  useEffect(() => {
    const isNotErrorNotFound = error && error.response?.status !== 404

    if (isNotErrorNotFound) {
      setHasErrorWhenGetCustomerCards(true)
      setCreatingErrorDetails(isSerializedApiError(error) ? error : undefined)
    }
  }, [error])

  return { customerCards: data ?? [], isFetching }
}
