import { useAtomValue } from 'jotai'
import { FC } from 'react'

import { customerAtoms } from 'src/features/crossSellCheckout/atoms/customerAtoms'
import { paymentAtoms } from 'src/features/payment/atoms/paymentAtoms'
import { SerializedApiError } from 'src/infra/typings/globalTypings'
import { isCustomerUnauthorized } from 'src/utils/unauthorizedApi'

import { CrossSellPaymentErrorMessage } from './CrossSellPaymentErrorMessage'
import { CrossSellTokenErrorMessage } from './CrossSellTokenErrorMessage'

export const CrossSellCheckoutErrorMessage: FC = () => {
  const hasErrorWhenGetCustomerCards = useAtomValue(
    customerAtoms.hasErrorWhenGetCustomerCards
  )
  const hasErrorCreatingOrder = useAtomValue(paymentAtoms.hasErrorCreatingOrder)

  const creatingOrderErrorDetails = useAtomValue(
    paymentAtoms.creatingOrderErrorDetails
  )

  const isAnRefused = isAnRefusedTransaction(creatingOrderErrorDetails)

  const isAnUnauthorized = isCustomerUnauthorized(creatingOrderErrorDetails)

  if (!hasErrorCreatingOrder && !hasErrorWhenGetCustomerCards) return null

  if (isAnUnauthorized) return <CrossSellTokenErrorMessage />

  return <CrossSellPaymentErrorMessage isAnRefused={isAnRefused} />
}

const isAnRefusedTransaction = (serializedApiError?: SerializedApiError) => {
  if (!serializedApiError) return false
  return serializedApiError.code === 'refused'
}
