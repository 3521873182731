import { PaymentMethods } from 'src/features/checkout/typings/legacyCheckoutTypings'
import {
  MethodsReturn,
  ModulePaymentProps,
} from 'src/modules/payment/paymentModule.types'

export function parsePaymentMethodDigitalCheckout(
  key: keyof MethodsReturn,
  paymentProps: ModulePaymentProps
) {
  switch (key) {
    case 'billet':
      return PaymentMethods.Billet
    case 'creditCard':
      const isLegacy =
        paymentProps.methods.creditCardConfig?.provider === 'legacy'
      return isLegacy
        ? PaymentMethods.LegacyCreditCard
        : PaymentMethods.CreditCard
    case 'nupay':
      return PaymentMethods.Nupay
    case 'pix':
      return PaymentMethods.Pix
  }
}
