import styled from '@emotion/styled'
import { css } from '@queimadiaria/ui'
import Image from 'next/image'
import { FC } from 'react'

export interface BannerProps {
  type:
    | 'desktop-on-side'
    | 'desktop-on-top'
    | 'mobile-on-top'
    | 'mobile-on-footer'
  banner: string
}

export const Banner: FC<BannerProps> = ({ type, banner }) => {
  if (type === 'desktop-on-side') {
    return (
      <ContainerOnSide>
        <Image
          alt='Banner Queima Diaria'
          layout='responsive'
          src={banner}
          width={287}
          height={967}
          quality={90}
        />
      </ContainerOnSide>
    )
  }
  if (type === 'mobile-on-footer') {
    return (
      <ContainerOnFooter>
        <Image
          layout='responsive'
          src={banner}
          alt='Checkout Seguro'
          width={371}
          height={82}
          quality={85}
        />
      </ContainerOnFooter>
    )
  }
  return (
    <TopContainer forPosition={type}>
      <Image
        layout='responsive'
        alt='banner de chamada para o plano'
        src={banner}
        width={type === 'desktop-on-top' ? 904 : 816}
        height={type === 'desktop-on-top' ? 197 : 472}
        quality={90}
      />
    </TopContainer>
  )
}

const TopContainer = styled.div<{
  forPosition: 'desktop-on-top' | 'mobile-on-top'
}>(({ forPosition }) =>
  css({
    display:
      forPosition === 'desktop-on-top'
        ? ['none', 'none', 'block', 'block', 'block']
        : ['block', 'block', 'none', 'none', 'none'],
    width: '100%',
    aspectRatio:
      forPosition === 'desktop-on-top'
        ? (904 / 197).toString()
        : (816 / 472).toString(),
    my: 'large',
    borderRadius: 'medium',
    backgroundColor: 'surface.300',
    position: 'relative',
    overflow: 'hidden',
  })
)

const ContainerOnSide = styled.div(
  css({
    display: ['none', 'none', 'none', 'block'],
    width: '100%',
    height: 'auto',
    aspectRatio: (287 / 967).toString(),
    borderRadius: 'medium',
    backgroundColor: 'surface.300',
    position: 'relative',
    overflow: 'hidden',
  })
)

const ContainerOnFooter = styled.div(
  css({
    display: ['block', 'block', 'none', 'none', 'none'],
    width: '100%',
    aspectRatio: (695 / 153).toString(),
    borderRadius: '18px',
    backgroundColor: 'surface.300',
    position: 'relative',
    overflow: 'hidden',
  })
)
