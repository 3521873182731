import { Box, Button, Text } from '@queimadiaria/ui'
import { useAtom } from 'jotai'
import { useAtomValue } from 'jotai'
import { FC, useEffect, useRef } from 'react'
import { useFormContext } from 'react-hook-form'

import { ErrorMessage } from 'src/features/layout/components/ErrorMessage'
import { paymentAtoms } from 'src/features/payment/atoms/paymentAtoms'
import { checkIsSelfIndication } from 'src/features/payment/utils/checkIsSelfIndication'
import { useIsOfferFormat } from 'src/features/plan/offer/hooks/useIsOfferFormat'
import { useProduct } from 'src/features/plan/product/hooks/useProduct'
import { usePaymentDigitalCheckoutContext } from 'src/screens/DigitalCheckout/hooks/modules/payment/usePaymentDigitalCheckoutContext'
import { isMonthlySubscriptionOrDirectFallback } from 'src/utils/isMonthlySubscriptionOrDirectFallback'

import { checkoutAtoms } from '../atoms/checkoutAtoms'
import { CheckoutFormPayloadOfAllTypes } from '../typings/checkoutFormTypings'
import { PaymentMethods } from '../typings/legacyCheckoutTypings'
import { PaymentErrorMessageProps } from '../typings/PaymentErrorMessageTypings'
import { isCheckoutFormPayloadWithLegacyCreditCard } from '../utils/checkoutFormTypeGuards'

export const PaymentErrorMessage: FC<PaymentErrorMessageProps> = ({
  isAnRefused,
}) => {
  const { getValues, setValue, reset } =
    useFormContext<CheckoutFormPayloadOfAllTypes>()

  const isOfferFormat = useIsOfferFormat()
  const { product } = useProduct()

  const { onSelectedMethodPayment, getIsVisibleMethod } =
    usePaymentDigitalCheckoutContext()

  const [, clearErrors] = useAtom(paymentAtoms.clearErrors)
  const creatingOrderErrorDetails = useAtomValue(
    paymentAtoms.creatingOrderErrorDetails
  )

  const isSelfIndication = checkIsSelfIndication(creatingOrderErrorDetails)

  const [, addBilletAsAvailablePaymentMethod] = useAtom(
    checkoutAtoms.addBilletAsAvailablePaymentMethod
  )
  const [, addPixAsAvailablePaymentMethod] = useAtom(
    checkoutAtoms.addPixAsAvailablePaymentMethod
  )
  const isMethodSelectionEnabled = useAtomValue(
    checkoutAtoms.isMethodSelectionEnabledAtom
  )

  const isNewCheckout = useAtomValue(checkoutAtoms.isNewCheckout)
  const [, showMorePaymentMethods] = useAtom(
    checkoutAtoms.showMorePaymentMethods
  )

  const isCreditCardInactive = useAtomValue(checkoutAtoms.isCreditCardInactive)

  const isSubscriptionMonthly = isMonthlySubscriptionOrDirectFallback(product)

  const onPressPayWithOtherCard = () => {
    clearErrors()
    onSelectedMethodPayment('creditCard')
    const values = getValues()
    if (isCheckoutFormPayloadWithLegacyCreditCard(values)) {
      reset({
        ...values,
        creditCardNumber: '',
        creditCardName: '',
        cardExpiration: '',
        cvv: '',
      })
      document.getElementsByName('creditCardNumber')[0]?.focus()
    }
  }
  const onPressPayWithBillet = () => {
    addBilletAsAvailablePaymentMethod()
    setValue('paymentMethod', PaymentMethods.Billet)
    onSelectedMethodPayment('billet')
    clearErrors()
  }

  const onPressPayWithPix = () => {
    addPixAsAvailablePaymentMethod()
    setValue('paymentMethod', PaymentMethods.Pix)
    onSelectedMethodPayment('pix')
    clearErrors()
  }

  const onPressPayWithNupay = () => {
    setValue('paymentMethod', PaymentMethods.Nupay)
    onSelectedMethodPayment('nupay')
    clearErrors()
  }

  const RefDivForScrollingToView = useRef<null | HTMLDivElement>(null)

  useEffect(() => {
    if (isNewCheckout) {
      showMorePaymentMethods()
    }
    if (RefDivForScrollingToView.current) {
      RefDivForScrollingToView.current.scrollIntoView()
    }
  }, [RefDivForScrollingToView])

  if (!isNewCheckout) {
    if (isSelfIndication) {
      return (
        <div ref={RefDivForScrollingToView}>
          <ErrorMessage
            title={
              isAnRefused
                ? 'Ops! Erro no pagamento.'
                : 'Ops! Falha no pagamento.'
            }
          >
            <Text
              variant='body-1'
              color='#595769'
              marginTop='16px'
              paddingX='40px'
            >
              Não é possível finalizar a compra com o mesmo E-Mail do usuário
              que realizou a indicação!
            </Text>
          </ErrorMessage>
        </div>
      )
    }
    return (
      <div ref={RefDivForScrollingToView}>
        <ErrorMessage
          title={
            isAnRefused ? 'Ops! Erro no pagamento.' : 'Ops! Falha no pagamento.'
          }
        >
          <Text variant='body-1' color='#595769'>
            <ol>
              <li>Verifique se as informações do seu cartão estão corretas.</li>
              <li>
                Se as informações estiverem corretas, verifique se o cartão está
                com limite liberado e com saldo suficiente.
              </li>
              <li>
                Caso o erro continue, entre em contato com a administradora do
                seu cartão.
              </li>
              <li>
                Ou se tiver outro cartão de crédito, clique no botão abaixo e
                faça sua inscrição com ele.
              </li>
            </ol>
          </Text>
          <Box
            sx={{
              display: 'flex',
              flexDirection: ['column', 'column', 'row', 'row'],
              justifyContent:
                !isMethodSelectionEnabled && !isOfferFormat
                  ? 'center'
                  : 'flex-end',
            }}
          >
            {!isMethodSelectionEnabled &&
              !isOfferFormat &&
              !isSubscriptionMonthly && (
                <>
                  {getIsVisibleMethod('pix') && (
                    <Button
                      children={
                        <Text
                          variant='subheadline-1'
                          css={{
                            fontSize: '14px',
                            lineHeight: '0px',
                          }}
                        >
                          Pagar com Pix
                        </Text>
                      }
                      variant='outlined'
                      onClick={onPressPayWithPix}
                      type='button'
                      size='large'
                      sx={{
                        backgroundColor: 'white',
                        marginRight: [null, null, '8px', '8px'],
                        marginBottom: ['16px', '16px', null, null],
                      }}
                    />
                  )}

                  {getIsVisibleMethod('nupay') && (
                    <Button
                      children={
                        <Text
                          variant='subheadline-1'
                          css={{
                            fontSize: '14px',
                            lineHeight: '0px',
                          }}
                        >
                          Pagar com Nubank
                        </Text>
                      }
                      variant='outlined'
                      onClick={onPressPayWithNupay}
                      type='button'
                      size='large'
                      sx={{
                        backgroundColor: 'white',
                        marginRight: [null, null, '8px', '8px'],
                        marginBottom: ['16px', '16px', null, null],
                      }}
                    />
                  )}

                  {getIsVisibleMethod('billet') && (
                    <Button
                      children={
                        <Text
                          variant='subheadline-1'
                          css={{
                            fontSize: '14px',
                            lineHeight: '0px',
                          }}
                        >
                          Pagar por Boleto
                        </Text>
                      }
                      variant='outlined'
                      onClick={onPressPayWithBillet}
                      type='button'
                      size='large'
                      sx={{
                        backgroundColor: 'white',
                        marginRight: [null, null, '8px', '8px'],
                        marginBottom: ['16px', '16px', null, null],
                      }}
                    />
                  )}
                </>
              )}
            {!isCreditCardInactive && getIsVisibleMethod('creditCard') && (
              <Button
                children={
                  <Text
                    variant='subheadline-1'
                    css={{
                      fontSize: '14px',
                      lineHeight: '0px',
                    }}
                  >
                    Outro Cartão
                  </Text>
                }
                onClick={onPressPayWithOtherCard}
                type='button'
                size='large'
              />
            )}
          </Box>
        </ErrorMessage>
      </div>
    )
  }

  return (
    <div ref={RefDivForScrollingToView}>
      <ErrorMessage title='Erro no pagamento.'>
        <Text
          variant='body-1'
          color='#DC6803'
          style={{ fontSize: '14px', lineHeight: '21px' }}
        >
          {isSelfIndication
            ? 'Não é possível finalizar a compra com o mesmo E-Mail do usuário que realizou a indicação!'
            : 'Revise as informações do cartão ou verifique se está com limite suficiente. Caso ainda não tenha sucesso, utilize uma da formas de pagamento alternativas.'}
        </Text>
      </ErrorMessage>
    </div>
  )
}
