import styled from '@emotion/styled'
import { Text } from '@queimadiaria/ui'
import { FC } from 'react'

import { useCheckoutDetails } from 'src/features/plan/checkout/hooks/useCheckoutDetails'

export const TermsOfUse: FC = () => {
  const { checkoutDetails } = useCheckoutDetails()
  return (
    <Text variant='body-2'>
      Ao continuar você aceita nossa{' '}
      <Redirect
        rel='noopener'
        target='_blank'
        href={checkoutDetails?.privacyPolicyRef}
      >
        Política de Privacidade
      </Redirect>{' '}
      e{' '}
      <Redirect rel='noopener' target='_blank' href={checkoutDetails?.termsRef}>
        Termos de Serviço
      </Redirect>
      .
    </Text>
  )
}
export const Redirect = styled.a`
  font-family: Graphik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #6e6c7c;
`
