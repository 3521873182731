import { authenticatedApi } from 'src/infra/api'
import { createUnknownSerializedApiError } from 'src/utils/createUnknownSerializedApiError'
import { fromPaymentErrorToSerializedApiError } from 'src/utils/fromPaymentErrorToSerializedApiError'

import { CustomerCreditCard } from '../types/customerCardsTyping'

async function fetchCustomerCards() {
  return authenticatedApi
    .url(`/subscriptions/customer-card`)
    .get()
    .internalError(error => {
      throw fromPaymentErrorToSerializedApiError(error.json)
    })
    .unauthorized(error => {
      throw fromPaymentErrorToSerializedApiError(error.json)
    })
    .badRequest(error => {
      throw fromPaymentErrorToSerializedApiError(error.json)
    })
    .fetchError(() => {
      throw createUnknownSerializedApiError()
    })
    .json<CustomerCreditCard[]>()
}

export const customerApi = {
  fetchCustomerCards,
}
