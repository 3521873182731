import { Box, Button, Flex, Logo, Text } from '@queimadiaria/ui'
import { useRouter } from 'next/router'
import { FC } from 'react'

interface UnknownServiceErrorMessageProps {
  title?: string
}
export const UnknownServiceErrorMessage: FC<
  UnknownServiceErrorMessageProps
> = ({ title }) => {
  const router = useRouter()

  const handleGoToCheckout = () => {
    router.push('/checkout/131')
  }

  const homeRedirect = () => {
    window.location.href = 'https://www.queimadiaria.com'
  }

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: `calc(100vh - ${RESERVERD_HEIGHT}px)`,
        p: 'large',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          height: '100vh',
          alignItems: 'center',
          maxWidth: '800px',
        }}
      >
        <Flex
          sx={{
            alignItems: 'center',
            boxSizing: 'border-box',
            flexDirection: ['column', 'column', 'row', 'row'],
            mx: ['xlarge', 'xlarge', 'xlarge', 0],
          }}
        >
          <Box sx={{ maxWidth: 150 }}>
            <Logo type='symbol' />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: ['center', 'center', 'baseline', 'baseline'],
              marginLeft: ['0', '0', '24px', '24px'],
            }}
          >
            <Text variant='headline-2'>{title ?? 'Serviço Indisponível'}</Text>

            <Text variant='body-1'>
              Sua ação não pode ser concluída, por favor tente novamente ou
              entre em contato com o suporte através do email{' '}
              <b>suporte@queimadiaria.com.br</b>.
            </Text>
            <Flex
              sx={{
                gridGap: 'large',
                mt: 'large',
                flexDirection: [
                  'column-reverse',
                  'column-reverse',
                  'row-reverse',
                  'row-reverse',
                ],
                width: '100%',
              }}
            >
              <Button onClick={handleGoToCheckout}>Tentar novamente</Button>
              <Button variant='outlined' onClick={homeRedirect}>
                Voltar a página inicial
              </Button>
            </Flex>
          </Box>
        </Flex>
      </Box>
    </Flex>
  )
}
const HEADER_HEIGHT = 50
const FOOTER_HEIGHT = 182
const RESERVERD_HEIGHT = HEADER_HEIGHT + FOOTER_HEIGHT
