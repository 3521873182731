import { useContext } from 'react'

import { PaymentDigitalCheckoutContext } from './PaymentDigitalCheckoutProvider'

export function usePaymentDigitalCheckoutContext() {
  const context = useContext(PaymentDigitalCheckoutContext)
  if (!context)
    throw Error(
      'Do not use this hook without a PaymentDigitalCheckout provider'
    )

  return context
}
