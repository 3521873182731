/* IMPORTANTE: Componente ****TEMPORÁRIO**** para fixar configs
do header de queima diária, NÃO TRATAR COMO PATTERN PARA TODO HEAD */

import { HeadPage } from '.'

export const QDHeadPage = () => {
  const headProps = {
    title: 'Checkout | Queima Diária',
    siteName: 'Queima Diária',
    imageUrl: 'https://img.queimadiaria.com/checkout/seo/queima-icon.png',
    siteUrl: `https://checkout.queimadiaria.com`,
    smallDescription: 'Programas de exercícios para fazer em casa e emagrecer',
    largeDescription:
      'A Queima Diária é uma plataforma com programas de nutrição e exercícios para fazer em casa. Faça sua assinatura e tenha acesso ilimitado aos famosos programas: Mamãe Sarada, Desafio Yoga e muito mais!',
    indexInGoogle: false,
  }
  return <HeadPage {...headProps} />
}
