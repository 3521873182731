import { AdyenCreditCard } from 'src/features/adyen/types/adyenCreditCardTyping'
import { EntityId } from 'src/infra/typings/globalTypings'

export interface CrossSellCheckoutFormWithCreditCard
  extends AdyenCreditCard,
    PaymentType {}

export interface CrossSellCheckoutFormWithCardId extends PaymentType {
  cardId: number
}

export interface CrossSellCheckoutFormPayloadWithCreditCard
  extends AdyenCreditCard,
    PaymentCrossSellBody,
    PaymentType {}

export interface CrossSellCheckoutFormPayloadWithCardId
  extends PaymentCrossSellBody,
    PaymentType {
  paymentProvider: string
  cardId: number
}

export interface PaymentCrossSellBody {
  installments: number
  offerId?: EntityId
  productSellGroupId?: EntityId | 'legacy-psg-id-placeholder'
  gaClientId?: string
  src?: string
  fpp?: string
  fpc?: string
  ip?: string
  mmTracker?: string | null
  pageLocation: string
  gaSessionId?: string
}

export interface PaymentType {
  paymentType: PaymentTypeEnum
}

export enum PaymentTypeEnum {
  CardId = 'card_id',
  CreditCard = 'credit_card',
}
