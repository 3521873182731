import { useAtomValue } from 'jotai'
import { FC } from 'react'

import { paymentAtoms } from 'src/features/payment/atoms/paymentAtoms'
import { SerializedApiError } from 'src/infra/typings/globalTypings'
import { usePaymentDigitalCheckoutContext } from 'src/screens/DigitalCheckout/hooks/modules/payment/usePaymentDigitalCheckoutContext'

import { checkoutAtoms } from '../atoms/checkoutAtoms'
import { PaymentErrorMessage } from './PaymentErrorMessage'

export const CheckoutErrorMessage: FC = () => {
  const hasErrorCreatingOrder = useAtomValue(paymentAtoms.hasErrorCreatingOrder)
  const isPaymentByBillet = useAtomValue(checkoutAtoms.isPaymentByBillet)
  const { selectedMethodPayment } = usePaymentDigitalCheckoutContext()

  const creatingOrderErrorDetails = useAtomValue(
    paymentAtoms.creatingOrderErrorDetails
  )

  const isAnRefused = isAnRefusedTransaction(creatingOrderErrorDetails)

  if (
    !hasErrorCreatingOrder ||
    isPaymentByBillet ||
    selectedMethodPayment === 'billet'
  )
    return null

  return <PaymentErrorMessage isAnRefused={isAnRefused} />
}

const isAnRefusedTransaction = (serializedApiError?: SerializedApiError) => {
  if (!serializedApiError) return false
  return serializedApiError.code === 'refused'
}
