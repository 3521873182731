import {
  addMilliseconds,
  addMinutes,
  addSeconds,
  isAfter,
  parseISO,
} from 'date-fns'

import { SpecialOfferConfig } from 'src/configs/remote/remoteConfigs.types'

import { SpecialOfferCheckout } from '../typings/specialOfferCheckout'

export type SaveSpecialOffer = {
  id: string
  endOfferTime: string
}

export const SPECIAL_OFFERS_KEY = 'QD_SO'

export function redirectSpecialOfferExpire(redirectUrl: string) {
  return {
    props: {},
    redirect: {
      destination: redirectUrl,
    },
  }
}

const limitCheck = (time: number) => Math.max(0, Math.min(time, 59))

export function calculateTimeSpecialOffer(
  config: SpecialOfferConfig,
  date: Date
) {
  return addSeconds(
    addMinutes(date, limitCheck(config.timerMinute)),
    limitCheck(config.timerSecond)
  )
}

export function getSpecialOfferCheckout(
  specialOfferConfig: SpecialOfferConfig,
  savedSpecialOffers: SaveSpecialOffer[],
  onSaveSpecialOffer: (saveData: SaveSpecialOffer) => void
) {
  let endTime = ''
  const todayNow = new Date()

  const findSpecialOffer = savedSpecialOffers.find(
    ({ id }) => id === specialOfferConfig.id
  )

  if (findSpecialOffer) {
    const dateEndTimeOffer = parseISO(findSpecialOffer.endOfferTime)
    if (isAfter(dateEndTimeOffer, todayNow)) {
      endTime = dateEndTimeOffer.toISOString()
    } else {
      return null
    }
  } else {
    if (
      !!specialOfferConfig.limitStartDate &&
      isAfter(todayNow, parseISO(specialOfferConfig.limitStartDate))
    ) {
      return null
    }
    const dateEndTimeOffer = calculateTimeSpecialOffer(
      specialOfferConfig,
      todayNow
    )
    endTime = dateEndTimeOffer.toISOString()
    onSaveSpecialOffer({
      id: specialOfferConfig.id,
      endOfferTime: endTime,
    })
  }

  return {
    ...specialOfferConfig,
    endTime,
    currentTime: todayNow.toISOString(),
  } as SpecialOfferCheckout
}

export function normalizeTimeSpecialOffer(
  dateCompare: Date,
  reference: number
) {
  return addMilliseconds(dateCompare, reference)
}

export function calculateSpecialOffferPercentage(
  fullPrice: number,
  displayPrice: number
) {
  return Math.floor(
    100 - Math.min(100, Math.max(0, (fullPrice * 100) / displayPrice))
  )
}
