import { useAtom } from 'jotai'
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useFormContext } from 'react-hook-form'

import { checkoutAtoms } from 'src/features/checkout/atoms/checkoutAtoms'
import { CheckoutFormPayloadWithNupay } from 'src/features/checkout/typings/checkoutFormTypings'
import { useCheckoutParamsFromUrl } from 'src/features/layout/hooks/useCheckoutParamsFromUrl'
import { useOffer } from 'src/features/plan/offer/hooks/useOffer'
import {
  KeyMethods,
  ModulePaymentProps,
  ModulePaymentReturn,
} from 'src/modules/payment/paymentModule.types'
import { useErrorFullScreenModal } from 'src/screens/DigitalCheckout/components/ErrorFullScreenModal/hooks/useErrorFullScreenModal'
import { parsePaymentMethodDigitalCheckout } from 'src/screens/DigitalCheckout/helpers/paymentMethod'
import { isFeatureEnabledBasedOnAnyArrayTruth } from 'src/utils/featureControlUtils'

import { useDigitalCheckoutContext } from '../../useDigitalCheckoutContext'
import { NupayDigitalCheckout } from './components/NupayDigitalCheckout'
import { PAYMENT_MODULE_EMPTY } from './constants'
import { definePaymentMethodDefault } from './helper/definePaymentMethodDefault'

export interface PaymentDigitalCheckoutProviderProps {
  children: React.ReactNode
}

export interface PaymentDigitalCheckoutContextValues {
  availableMethods: ModulePaymentReturn
  selectedMethodPayment: KeyMethods | null
  onSelectedMethodPayment: (method: KeyMethods | null) => void
  paramsConfigPayment?: ModulePaymentProps
  visibleMethodsPayment: KeyMethods[]
  getIsVisibleMethod: (method: KeyMethods) => boolean
  isAbleFinishBuy: boolean
  PaymentComponent: JSX.Element
  getIsDisabledMethodPayment: (method: KeyMethods) =>
    | {
        keyMethod: KeyMethods
        message: string
      }
    | undefined
  setPaymentCheckoutToFallback: () => void
  retryPaymentMethod: (method: KeyMethods) => void
}

export const PaymentDigitalCheckoutContext = createContext(
  {} as PaymentDigitalCheckoutContextValues
)

type DisableMethodsPaymentType = Array<{
  keyMethod: KeyMethods
  message: string
}>

export const PaymentDigitalCheckoutProvider = ({
  children,
}: PaymentDigitalCheckoutProviderProps) => {
  const {
    modulesCheckout,
    configCheckout,
    getFeatureFlag,
    form: { addDisabledField },
  } = useDigitalCheckoutContext()
  const { isBilletMethodEnabled, isSalesRecovery, methodEmptyDefault } =
    useCheckoutParamsFromUrl()
  const [isMethodSelectionEnabled] = useAtom(
    checkoutAtoms.isMethodSelectionEnabledAtom
  )
  const [isPaymentCheckoutInFallback, setIsPaymentCheckoutInFallback] = useAtom(
    checkoutAtoms.paymentCheckoutInFallback
  )

  const [, setIsPaymentByBillet] = useAtom(checkoutAtoms.isPaymentByBillet)
  const [, setIsPaymentByPix] = useAtom(checkoutAtoms.isPaymentByPix)

  const isCheckoutInFallback = useMemo(
    () =>
      isBilletMethodEnabled ||
      isMethodSelectionEnabled ||
      isPaymentCheckoutInFallback,
    [
      isBilletMethodEnabled,
      isMethodSelectionEnabled,
      isPaymentCheckoutInFallback,
    ]
  )

  const { offer } = useOffer()
  const { errorDigitalCheckout, showErrorFullScreenModal } =
    useErrorFullScreenModal()
  const { nupayDefault } = useCheckoutParamsFromUrl()

  const { watch, setValue } = useFormContext<CheckoutFormPayloadWithNupay>()

  const nupayValues = watch('nupay')

  const [selectedMethodPayment, setSelectedMethodPayment] =
    useState<null | KeyMethods>(null)
  const [disabledMethodsPayment, setDisabledMethodsPayment] =
    useState<DisableMethodsPaymentType>([])
  const availableMethods = useMemo(
    () => modulesCheckout?.paymentModule || PAYMENT_MODULE_EMPTY,
    [modulesCheckout]
  )

  const paramsConfigPayment = configCheckout?.modulesConfigs.find(
    ({ name }) => name === 'paymentModule'
  )?.params as ModulePaymentProps | undefined

  const onSelectedMethodPayment = (method: KeyMethods | null) => {
    setSelectedMethodPayment(method)
  }

  useEffect(() => {
    setValue('nupay', undefined)
    setIsPaymentByBillet(selectedMethodPayment === 'billet')
    setIsPaymentByPix(selectedMethodPayment === 'pix')
  }, [selectedMethodPayment])

  const visibleMethodsPayment = useMemo(() => {
    const methodsAvailableConfig: KeyMethods[] = Object.keys(
      availableMethods.methods
    ) as KeyMethods[]

    //couponOrOrderBumpAreActive must be temporary
    const couponOrOrderBumpAreActive =
      !!offer?.isDisplayCoupon ||
      (!!offer?.orderBump && offer.orderBump.isActive)

    const showMethodsConditions = {
      nupay: [
        isCheckoutInFallback &&
          (getFeatureFlag?.('nupay', 'nupay_fallback') || false) &&
          !couponOrOrderBumpAreActive,
        !isCheckoutInFallback &&
          (offer?.isAvailableNupay || false) &&
          (getFeatureFlag?.('nupay', 'nupay_initial') || false) &&
          !couponOrOrderBumpAreActive,
      ],
      pix: [isCheckoutInFallback],
      billet: [isCheckoutInFallback],
      creditCard: [],
    }

    return methodsAvailableConfig.filter(method =>
      isFeatureEnabledBasedOnAnyArrayTruth(showMethodsConditions[method])
    )
  }, [offer, isCheckoutInFallback, getFeatureFlag])

  useEffect(() => {
    if (!!isCheckoutInFallback) {
      onSelectedMethodPayment(null)
      return
    }

    const onSelectDefault = definePaymentMethodDefault({
      nupayDefault,
      visibleMethodsPayment,
      methodEmptyDefault,
    })

    onSelectedMethodPayment(onSelectDefault)
  }, [
    visibleMethodsPayment,
    nupayDefault,
    isCheckoutInFallback,
    methodEmptyDefault,
  ])

  useEffect(() => {
    paramsConfigPayment &&
      !!selectedMethodPayment &&
      setValue(
        'paymentMethod',
        parsePaymentMethodDigitalCheckout(
          selectedMethodPayment,
          paramsConfigPayment
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ) as any
      )
  }, [selectedMethodPayment, paramsConfigPayment])

  useEffect(() => {
    if (isSalesRecovery) onSelectedMethodPayment('creditCard')
  }, [isSalesRecovery])

  const getIsVisibleMethod = (method: KeyMethods) => {
    return !!visibleMethodsPayment.find(
      visibleMethod => visibleMethod === method
    )
  }

  const isAbleFinishBuy = useMemo(() => {
    return (
      !!selectedMethodPayment &&
      (selectedMethodPayment !== 'nupay' || !!nupayValues?.fundingSource) &&
      selectedMethodPayment !== 'pix' &&
      visibleMethodsPayment.includes(selectedMethodPayment)
    )
  }, [selectedMethodPayment, visibleMethodsPayment, nupayValues?.fundingSource])

  const addDisabledMethodPayment = useCallback(
    (method: KeyMethods, message = 'Serviço indisponível no momento.') => {
      if (disabledMethodsPayment.find(({ keyMethod }) => keyMethod === method))
        return

      if (method === selectedMethodPayment) setSelectedMethodPayment(null)
      setDisabledMethodsPayment(oldValues => [
        ...oldValues,
        {
          keyMethod: method,
          message,
        },
      ])
    },
    [disabledMethodsPayment, selectedMethodPayment]
  )

  const retryPaymentMethod = useCallback(
    (method: KeyMethods) => {
      const disabledMethodToRetry = disabledMethodsPayment.find(
        ({ keyMethod }) => keyMethod === method
      )
      if (!disabledMethodToRetry) return
      setDisabledMethodsPayment(oldValues => [
        ...oldValues.filter(({ keyMethod }) => keyMethod !== method),
      ])
      setSelectedMethodPayment(method)
    },
    [disabledMethodsPayment, selectedMethodPayment]
  )

  const getIsDisabledMethodPayment = useCallback(
    (method: KeyMethods) => {
      return disabledMethodsPayment.find(
        ({ keyMethod }) => keyMethod === method
      )
    },
    [disabledMethodsPayment]
  )

  const setPaymentCheckoutToFallback = useCallback(() => {
    setIsPaymentCheckoutInFallback(true)
  }, [])

  const handlePaymentFatalError = (title: string, text: string) => {
    setSelectedMethodPayment(null)
    showErrorFullScreenModal(title, text)
  }

  const PaymentComponent = useMemo(() => {
    if (!selectedMethodPayment) return <></>

    if (selectedMethodPayment === 'nupay' && !!availableMethods.methods.nupay) {
      return (
        <NupayDigitalCheckout
          nupayMethod={availableMethods.methods.nupay}
          onNupayFatalError={handlePaymentFatalError}
          onInvalidNupayOption={(msg?: string) =>
            addDisabledMethodPayment('nupay', msg)
          }
        />
      )
    }

    return <></>
  }, [
    selectedMethodPayment,
    availableMethods,
    addDisabledField,
    addDisabledMethodPayment,
    errorDigitalCheckout,
  ])

  return (
    <PaymentDigitalCheckoutContext.Provider
      value={{
        availableMethods,
        selectedMethodPayment,
        onSelectedMethodPayment,
        paramsConfigPayment,
        visibleMethodsPayment,
        getIsVisibleMethod,
        isAbleFinishBuy,
        PaymentComponent,
        getIsDisabledMethodPayment,
        setPaymentCheckoutToFallback,
        retryPaymentMethod,
      }}
    >
      {children}
    </PaymentDigitalCheckoutContext.Provider>
  )
}
