import { atom } from 'jotai'

import { SerializedApiError } from 'src/infra/typings/globalTypings'

const hasErrorWhenGetCustomerCards = atom(false)
const creatingErrorDetails = atom<SerializedApiError | undefined>(undefined)

export const customerAtoms = {
  hasErrorWhenGetCustomerCards,
  creatingErrorDetails,
}
