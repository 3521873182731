import { Box, Flex, Icon, Text } from '@queimadiaria/ui'
import { useAtomValue } from 'jotai'
import { FC } from 'react'

import { checkoutAtoms } from 'src/features/checkout/atoms/checkoutAtoms'

interface ErrorMessageProps {
  title: string
}
export const ErrorMessage: FC<ErrorMessageProps> = ({ title, children }) => {
  const isNewCheckout = useAtomValue(checkoutAtoms.isNewCheckout)

  if (!isNewCheckout) {
    return (
      <Flex
        sx={{
          flexDirection: 'column',
          border: '1px solid #B00020',
          borderRadius: '8px',
          boxSizing: 'border-box',
          marginBlock: '32px',
          padding: '24px',
        }}
      >
        <Text variant='headline-3' color='feedback.negative'>
          <Flex sx={{ alignItems: 'center', marginLeft: '20px' }}>
            <Icon
              name='exclamation-circle'
              color='feedback.negative'
              marginRight={8}
            />
            {title}
          </Flex>
        </Text>

        {children}
      </Flex>
    )
  }

  return (
    <Box sx={{ background: '#FAFAFA', padding: '0px 32px 0px 32px' }}>
      <Flex
        sx={{
          boxSizing: 'border-box',
          background: '#FEF0C7',
          border: '1px solid #DC6803',
          borderRadius: '4px',
          padding: '16px 18px',
          alignItems: 'start',
        }}
      >
        <img src='/assets/alertOrange.svg' alt='icone de alerta' />

        <Box sx={{ marginInlineStart: '18px' }}>
          <Text variant='subheadline-2' color='#DC6803'>
            {title}
          </Text>

          {children}
        </Box>
      </Flex>
    </Box>
  )
}
