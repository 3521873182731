import { useAtom } from 'jotai'
import { useCallback, useEffect, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

import { CheckoutFormPayloadWithNupay } from 'src/features/checkout/typings/checkoutFormTypings'
import { paymentAtoms } from 'src/features/payment/atoms/paymentAtoms'
import { useOffer } from 'src/features/plan/offer/hooks/useOffer'
import {
  NupayErrorType,
  NupayReturn,
  NupayValues,
} from 'src/modules/payment/methods/nupay/NupayMethod.types'

import { useDigitalCheckoutContext } from '../../../useDigitalCheckoutContext'

export type NupayDigitalCheckoutProps = {
  nupayMethod: NupayReturn
  onInvalidNupayOption: (message?: string) => void
  onNupayFatalError: (title: string, text: string) => void
}

export function NupayDigitalCheckout({
  nupayMethod,
  onInvalidNupayOption,
  onNupayFatalError,
}: NupayDigitalCheckoutProps) {
  const { setValue, trigger, watch } =
    useFormContext<CheckoutFormPayloadWithNupay>()
  const {
    form: { addDisabledField },
  } = useDigitalCheckoutContext()

  const { chosenOffer } = useOffer()

  const [document, email, installments] = watch([
    'document',
    'email',
    'installments',
  ])

  const [, clearErrors] = useAtom(paymentAtoms.clearErrors)

  useEffect(() => {
    clearErrors()
  }, [])

  const onChangeNupayValues = useCallback(
    (values: NupayValues) => {
      setValue('nupay', values)
      if (values.isSendedAuthorization) {
        addDisabledField('document')
        addDisabledField('email')
      }

      if (!!values.fundingSource && !!values.installment) {
        installments !== values.installment &&
          setValue('installments', values.installment)
      }
    },
    [addDisabledField, setValue]
  )

  const onErrorPayment = useCallback(
    (typeError: NupayErrorType) => {
      console.error(typeError)
      if (typeError === 'ValidationError') {
        trigger(['document', 'email'])
        if (typeof window !== 'undefined') {
          window.scrollTo(0, 0)
        }
        return
      }

      if (typeError === 'UnauthorizedError') {
        onNupayFatalError(
          'Ops! Seu pagamento não foi autorizado pelo aplicativo.',
          'Para garantir sua assinatura, faça uma nova autorização clicando no botão abaixo:'
        )
        return
      }

      if (typeError === 'InsufficientFundsError') {
        onInvalidNupayOption('Limite e saldo insuficientes para essa compra.')
        return
      }

      if (typeError === 'TimeoutError') {
        onNupayFatalError(
          'Ops! Seu tempo expirou :(',
          'A autorização não foi concluída a tempo. Para garantir sua assinatura, faça uma nova autorização clicando no botão abaixo:'
        )
        return
      }

      onInvalidNupayOption()
    },
    [trigger, onInvalidNupayOption]
  )

  const NupayComponent = useMemo(
    () => nupayMethod.component,
    [nupayMethod.component]
  )

  const fallbackInstallment = useMemo(() => {
    return (
      chosenOffer?.installmentsItems[
        chosenOffer?.installmentsItems?.length - 1 || 0
      ] || null
    )
  }, [chosenOffer])

  const userDataNupay = useMemo(() => {
    return {
      document,
      email,
    }
  }, [document, email])

  const offerNupay = useMemo(() => {
    if (!chosenOffer?.id || !fallbackInstallment) return null

    return {
      id: Number(chosenOffer.id),
      fallbackInstallment,
    }
  }, [chosenOffer, fallbackInstallment])

  if (!offerNupay) return null

  return (
    <NupayComponent
      onError={onErrorPayment}
      onChange={onChangeNupayValues}
      userData={userDataNupay}
      offer={offerNupay}
    />
  )
}
