/**
 * Determines if a feature should be enabled based on an array of boolean conditions.
 *
 * The function returns `true` if the array is empty, meaning there are no conditions to restrict the feature.
 * If the array contains conditions, it returns `true` if any condition is `true`.
 * If all conditions are `true`, the function returns `true`.
 * If all conditions are `false`, the function returns `false`.
 *
 * @param conditions - An array of boolean conditions to evaluate.
 * @returns `true` if the feature should be enabled, `false` otherwise.
 */
export function isFeatureEnabledBasedOnAnyArrayTruth(
  conditions: boolean[]
): boolean {
  if (conditions.length === 0) return true
  return conditions.find(condition => condition) ?? false
}
